export const menuLinks = (menu_data) => {
    //console.log("menu_data", menu_data);

    var menu_link = '';

    if(menu_data){
        if(menu_data?.strapi_parent?.slug_new){
            menu_link = '/'+menu_data?.strapi_parent?.slug_new+'/'+menu_data?.slug_new+'/';
        } else{
            menu_link = '/'+menu_data.slug_new+'/';
        }
    }
    return menu_link;
}